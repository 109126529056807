const CMS_SERVICE = '/user-service';
const HRM_SYSTEM = '/hrm-system';
export default {
  report_service: {
    OVERVIEW: {
      url: CMS_SERVICE + '/overview',
      method: 'GET',
    },
    OVERVIEW_LINE: {
      url: CMS_SERVICE + '/chart',
      method: 'GET',
    },
    TOTAL_ARTICLE_BY_CREATED: {
      url: CMS_SERVICE + '/chart/article-by-created',
      method: 'GET',
    },
    TOTAL_ARTICLE_BY_STATUS: {
      url: CMS_SERVICE + '/chart/article-by-status',
      method: 'GET',
    },
  },
  role: {
    GET_ALL: {
      url: CMS_SERVICE + '/role/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/role',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/role',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/role',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/role',
      method: 'DELETE',
    },
  },
  role_menu: {
    GET_BY_ROLE: {
      url: CMS_SERVICE + '/role-menu',
      method: 'GET',
    },
  },
  user_role: {
    GET_BY_USER: {
      url: CMS_SERVICE + '/user-role',
      method: 'GET',
    },
  },
  category: {
    ALL: {
      url: CMS_SERVICE + '/category/get-all',
      method: 'GET',
    },
    ALL_WITHOUT_TREE: {
      url: CMS_SERVICE + '/category/get-all-without-tree',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/category',
      method: 'GET',
    },
    DETAIL: {
      url: CMS_SERVICE + '/category/',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/category',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/category',
      method: 'PUT',
    },
    UPDATE_STATUS: {
      url: CMS_SERVICE + '/category/update-status',
      method: 'PUT',
    },

    DELETE: {
      url: CMS_SERVICE + '/category',
      method: 'DELETE',
    },
  },
  menu: {
    SEARCH: {
      url: CMS_SERVICE + '/menu',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/menu',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/menu',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/menu',
      method: 'DELETE',
    },
  },
  user: {
    SEARCH: {
      url: CMS_SERVICE + '/user',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/user',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/user',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/user',
      method: 'DELETE',
    },
    CHECK_USERNAME_EXISTED: {
      url: CMS_SERVICE + '/user/check',
      method: 'GET',
    },
    GET_USER_INFO: {
      url: CMS_SERVICE + '/user/get-user-info',
      method: 'GET',
    },
    GET_NOTIFICATIONS: {
      url: HRM_SYSTEM + '/notify',
      method: 'GET',
    },
    GET_TOTAL_NOTIFICATION_UN_READ: {
      url: HRM_SYSTEM + '/notify/count',
      method: 'GET',
    },
    MARK_READ_NOTIFICATION: {
      url: CMS_SERVICE + '/notify/seen',
      method: 'PATCH',
    },
    PATCH_UPDATE: {
      url: CMS_SERVICE + '/user',
      method: 'PATCH',
    },
    RESET_PASSWORD: {
      url: CMS_SERVICE + '/user/',
      method: 'PUT',
    },
  },
  auth: {
    LOGIN: {
      url: CMS_SERVICE + '/oauth/token',
      method: 'POST',
    },
    LOGOUT: {
      url: CMS_SERVICE + '/oauth/revoke',
      method: 'DELETE',
    },
    REFRESH_TOKEN: {
      url: CMS_SERVICE + '/oauth/refresh-token',
      method: 'POST',
    },
    PATCH_UPDATE: {
      url: CMS_SERVICE + '/user',
      method: 'PATCH',
    },
    RE_CAPTCHA: {
      url: CMS_SERVICE + '/captcha/generate',
      method: 'GET',
    },
  },
  upload_service: {
    UPLOAD: {
      url: HRM_SYSTEM + '/upload',
      method: 'POST',
    },
  },
  articles: {
    ALL: {
      url: CMS_SERVICE + '/article/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/article',
      method: 'GET',
    },
    SEARCH_APPROVE: {
      url: CMS_SERVICE + '/article/approve',
      method: 'GET',
    },
    SEARCH_PUBLIC: {
      url: CMS_SERVICE + '/article/publish',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/article',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/article',
      method: 'PUT',
    },
    UPDATE_STATUS: {
      url: CMS_SERVICE + '/article/update-status',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/article',
      method: 'DELETE',
    },
    UN_PUBLISH: {
      url: CMS_SERVICE + '/article/un-publish',
      method: 'PATCH',
    },
    APPROVAL: {
      url: CMS_SERVICE + '/article/approve',
      method: 'PATCH',
    },
    PUBLISH: {
      url: CMS_SERVICE + '/article/publish',
      method: 'PATCH',
    },
    UPDATE_HOT: {
      url: CMS_SERVICE + '/article/update-is-hot',
      method: 'PUT',
    },
  },
  media_files: {
    ALL: {
      url: CMS_SERVICE + '/media-files/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/media-files',
      method: 'GET',
    },
    SEARCH_APPROVE: {
      url: CMS_SERVICE + '/media-files/approve',
      method: 'GET',
    },
    SEARCH_PUBLIC: {
      url: CMS_SERVICE + '/media-files/publish',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/media-files',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/media-files',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/media-files',
      method: 'DELETE',
    },
    UN_PUBLISH: {
      url: CMS_SERVICE + '/media-files/un-publish',
      method: 'PATCH',
    },
    APPROVAL: {
      url: CMS_SERVICE + '/media-files/approve',
      method: 'PATCH',
    },
    PUBLISH: {
      url: CMS_SERVICE + '/media-files/publish',
      method: 'PATCH',
    },
  },
  chart: {
    DOMAIN_IP: {
      url: CMS_SERVICE + '/chart/account-domain-ip',
      method: 'GET',
    },
    ORGANIZATION: {
      url: CMS_SERVICE + '/chart/organization',
      method: 'GET',
    },
    STATUS: {
      url: CMS_SERVICE + '/chart/status',
      method: 'GET',
    },
    SUCCESS_STATUS: {
      url: CMS_SERVICE + '/chart/success-or-not',
      method: 'GET',
    },
    SUMMARY: {
      url: CMS_SERVICE + '/chart/summary',
      method: 'GET',
    },
    MANAGE_DATA: {
      url: CMS_SERVICE + '/agency/get-chart',
      method: 'GET',
    },
    PROVIDE_DATA: {
      url: CMS_SERVICE + 'agency/get-chart-dataset',
      method: 'GET',
    },
    FIELD_DATA: {
      url: CMS_SERVICE + '/fields/chart-field-dataset',
      method: 'GET',
    },
    TOTAL_FILE: {
      url: CMS_SERVICE + '/file/total-files',
      method: 'GET',
    },
    TOTAL_FILE_UPLOAD: {
      url: CMS_SERVICE + '/file/total-files-upload-by-month',
      method: 'GET',
    },
    TOTAL_FILE_DOWNLOAD: {
      url: CMS_SERVICE + '/file/total-files-download-by-month',
      method: 'GET',
    },
    TOTAL_AGENCIES_UPLOADED: {
      url: CMS_SERVICE + '/file/total-agencies-uploaded',
      method: 'GET',
    },
    TOP_FILE_UPLOAD: {
      url: CMS_SERVICE + '/file/top-10',
      method: 'GET',
    },
    TOP_AGENCIES: {
      url: CMS_SERVICE + '/file/top-10-agencies',
      method: 'GET',
    },
    FILE_EXTENSION: {
      url: CMS_SERVICE + '/file/files-extension',
      method: 'GET',
    },
  },
  procedure: {
    SEARCH: {
      url: CMS_SERVICE + '/procedure',
      method: 'GET',
    },
    CATEGORIES: {
      url: CMS_SERVICE + '/procedure/categories',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/procedure',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/procedure',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/procedure',
      method: 'DELETE',
    },
  },
  qAndA: {
    SEARCH: {
      url: CMS_SERVICE + '/qa',
      method: 'GET',
    },
    SEARCH_FA: {
      url: CMS_SERVICE + '/qa/frequently-asked',
      method: 'GET',
    },
    SEARCH_QA: {
      url: CMS_SERVICE + '/qa/q-and-a',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/qa',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/qa',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/qa',
      method: 'DELETE',
    },
  },
  topics: {
    GET_ALL: {
      url: CMS_SERVICE + '/topics/all',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: CMS_SERVICE + '/topics',
      method: 'GET',
    },
    GET_BY_ID: {
      url: CMS_SERVICE + '/topics/',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/topics/create',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/topics/update',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/topics/',
      method: 'DELETE',
    },
  },
  APP_PARAM: {
    SEARCH: {
      url: HRM_SYSTEM + '/app-params',
      method: 'GET',
    },
    GET_ALL_COMPANY: {
      url: CMS_SERVICE + '/company',
      method: 'GET',
    },
  },
  legislation: {
    SEARCH: {
      url: CMS_SERVICE + '/legislation',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/legislation',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/legislation',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/legislation',
      method: 'DELETE',
    },
  },
  introduce: {
    SEARCH: {
      url: CMS_SERVICE + '/introduce',
      method: 'GET',
    },
    CATEGORIES: {
      url: CMS_SERVICE + '/introduce/categories',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/introduce',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/introduce',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/introduce',
      method: 'DELETE',
    },
  },
  banners: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/banners',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/banners/all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/banners/create',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/banners/update',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/banners/',
      method: 'DELETE',
    },
  },
  config: {
    agency: {
      GET_ALL: {
        url: CMS_SERVICE + '/agency/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/agency',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/agency',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/agency',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/agency/',
        method: 'PUT',
      },
    },
    majors: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-major/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-major',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-major',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-major',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-major/',
        method: 'PUT',
      },
    },
    groups: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-group/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-group',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-group',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-group',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-group/',
        method: 'PUT',
      },
    },
    fields: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-fields/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-fields',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-fields',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-fields',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-fields/',
        method: 'PUT',
      },
    },
    types: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-type/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-type',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-type',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-type',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-type/',
        method: 'PUT',
      },
    },
  },
  recruitment: {
    GET_ALL: {
      url: CMS_SERVICE + '/recruitment/all',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: CMS_SERVICE + '/recruitment',
      method: 'GET',
    },
    GET_BY_CODE: {
      url: CMS_SERVICE + '/recruitment/detail',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/recruitment',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/recruitment',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/recruitment/',
      method: 'DELETE',
    },
  },
  CONTACT: {
    getAll: {
      url: CMS_SERVICE + '/contact',
      method: 'GET',
    },
    updateView: {
      url: CMS_SERVICE + '/contact/',
      method: 'PUT',
    },
    delete: {
      url: CMS_SERVICE + '/contact/',
      method: 'DELETE',
    },
  },
  caseStudy: {
    GET_WEB_URL: {
      url: CMS_SERVICE + '/web-url',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/case-study/get-all',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: CMS_SERVICE + '/case-study',
      method: 'GET',
    },
    GET_BY_CODE: {
      url: CMS_SERVICE + '/case-study/',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/case-study',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/case-study',
      method: 'PUT',
    },
    UPDATE_STATUS: {
      url: CMS_SERVICE + '/case-study/update-status',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/case-study/',
      method: 'DELETE',
    },
  },
  DEPARTMENT: {
    LIST_UNIT: {
      url: HRM_SYSTEM + '/departments/list-units',
      method: 'GET',
    },
    UPDATE_DEPARTMENT: {
      url: HRM_SYSTEM + '/departments/update-department',
      method: 'PUT',
    },
    CREATE_DEPARTMENT: {
      url: HRM_SYSTEM + '/departments/create-department',
      method: 'POST',
    },
    UPDATE_UNIT: {
      url: HRM_SYSTEM + '/departments/update-unit',
      method: 'PUT',
    },
    CREATE_UNIT: {
      url: HRM_SYSTEM + '/departments/create-unit',
      method: 'POST',
    },
    DELETE_UNIT: {
      url: HRM_SYSTEM + '/departments/delete-unit',
      method: 'DELETE',
    },
    DELETE_DEPARTMENT: {
      url: HRM_SYSTEM + '/departments/delete-department',
      method: 'DELETE',
    },
    GET_PROFILE_IN_DEPARTMENT: {
      url: HRM_SYSTEM + '/departments/department-profiles',
      method: 'GET',
    },
    GET_PROFILE_IN_UNIT: {
      url: HRM_SYSTEM + '/departments/list-unit-profiles',
      method: 'GET',
    },
    DELETE_DEPARTMENT_PROFILE: {
      url: HRM_SYSTEM + '/departments/delete-department-profile',
      method: 'DELETE',
    },
    DELETE_UNIT_PROFILE: {
      url: HRM_SYSTEM + '/departments/delete-unit-profile',
      method: 'DELETE',
    },
    ADD_DEPARTMENT_PROFILE: {
      url: HRM_SYSTEM + '/departments/add-department-profile',
      method: 'POST',
    },
    LIST_DEPARTMENT: {
      url: HRM_SYSTEM + '/departments/list-departments',
      method: 'GET',
    },
    EXPORT_DEPARTMENT_PROFILE: {
      url: HRM_SYSTEM + '/export/department-profiles',
      method: 'GET',
    },
    EXPORT_UNIT_PROFILE: {
      url: HRM_SYSTEM + '/export/unit-profiles',
      method: 'GET',
    },
  },
  WORK_SHIFT: {
    GET_ALL: {
      url: HRM_SYSTEM + '/work_shifts/list-work-shifts',
      method: 'GET',
    },
  },
  profileManagement: {
    GET_ALL: {
      url: HRM_SYSTEM + '/profiles',
      method: 'GET',
    },
    GET_LIST_NAME: {
      url: HRM_SYSTEM + '/profiles/get-list-name',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: HRM_SYSTEM + '/profiles/verify',
      method: 'GET',
    },
    GET_BY_IDS: {
      url: HRM_SYSTEM + '/profiles/',
      method: 'GET',
    },
    CREATE: {
      url: HRM_SYSTEM + '/profiles',
      method: 'POST',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/profiles',
      method: 'PUT',
    },
    DELETE: {
      url: HRM_SYSTEM + '/profiles/',
      method: 'DELETE',
    },
    GET_ALL_UNIT: {
      url: HRM_SYSTEM + '/departments/list-units',
      method: 'GET',
    },
    GET_REGISTER_IN_OUT: {
      url: HRM_SYSTEM + '/register-in-out',
      method: 'GET',
    },
    GET_BY_UNIT_ID: {
      url: HRM_SYSTEM + '/departments/list-departments',
      method: 'GET',
    },
    DOWNLOAD_FILE: {
      url: HRM_SYSTEM + '/export/profiles-base-file',
      method: 'GET',
    },
    IMPORT_EXCEL: {
      url: HRM_SYSTEM + '/export/import-profiles-file',
      method: 'POST',
    },
  },
  ATTENDANCE: {
    GET_ALL: {
      url: HRM_SYSTEM + '/attendances/list-attendance-records',
      method: 'GET',
    },
    CHANGE_STATUS: {
      url: HRM_SYSTEM + '/attendances/toggle-status',
      method: 'POST',
    },
    EXPORT: {
      url: HRM_SYSTEM + '/export/attendance-records',
      method: 'GET',
    },
    AGGREGATE: {
      url: HRM_SYSTEM + '/attendances/aggregate',
      method: 'GET',
    },
    EXPORT_DETAIL: {
      url: HRM_SYSTEM + '/export/aggregate',
      method: 'GET',
    },
    EXPORT_GENERAL: {
      url: HRM_SYSTEM + '/export/time-keeping-report',
      method: 'GET',
    },
  },
  TIME: {
    GET_ALL: {
      url: HRM_SYSTEM + '/work_shifts/list-work-shifts',
      method: 'GET',
    },
    CREATE: {
      url: HRM_SYSTEM + '/work_shifts/create-work-shift',
      method: 'POST',
    },
    DELETE: {
      url: HRM_SYSTEM + '/work_shifts/delete-work-shift',
      method: 'DELETE',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/work_shifts/update-work-shift',
      method: 'PUT',
    },
    GET_DETAIL: {
      url: HRM_SYSTEM + '/work_shifts/by-id',
      method: 'GET',
    },
  },
  LIST_UNIT: {
    GET_ALL: {
      url: HRM_SYSTEM + '/departments/list-units',
      method: 'GET',
    },
  },
  LIST_DEPARTMENT: {
    GET_ALL: {
      url: HRM_SYSTEM + '/departments/list-departments',
      method: 'GET',
    },
  },
  LIST_BUILDING: {
    GET_ALL: {
      url: HRM_SYSTEM + '/building/get-all',
      method: 'GET',
    },
  },
  nationality: {
    GET_ALL: {
      url: HRM_SYSTEM + '/nationality',
      method: 'GET',
    },
  },
  province: {
    GET_ALL: {
      url: HRM_SYSTEM + '/provinces/get-all',
      method: 'GET',
    },
  },
  EVENT: {
    GET_ALL: {
      url: HRM_SYSTEM + '/events',
      method: 'GET',
    },
    DELETE: {
      url: HRM_SYSTEM + '/events/',
      method: 'DELETE',
    },
    GET_DETAIL: {
      url: HRM_SYSTEM + '/events/',
      method: 'GET',
    },
    STATISTIC_DOOR: {
      url: HRM_SYSTEM + '/dashboards/statistic-door',
      method: 'GET',
    },
  },
  CAMERA: {
    GET_CAMERA_IN_GROUP: {
      url: HRM_SYSTEM + '/cameras',
      method: 'GET',
    },
    GROUP_CAMERA: {
      url: HRM_SYSTEM + '/group-cameras',
      method: 'GET',
    },
  },
  REGISTER_IN_OUT: {
    GET_ALL: {
      url: HRM_SYSTEM + '/register-in-out',
      method: 'GET',
    },
    CREATE: {
      url: HRM_SYSTEM + '/register-in-out',
      method: 'POST',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/register-in-out',
      method: 'PUT',
    },
  },
  ACCESS_CONTROL: {
    DASHBOARD: {
      url: HRM_SYSTEM + '/dashboards/in-out',
      method: 'GET',
    },
    DASHBOARD_TABLE: {
      url: HRM_SYSTEM + '/register-in-out',
      method: 'GET',
    },
    DASHBOARD_STRANGER: {
      url: HRM_SYSTEM + '/dashboards/statistic-stranger',
      method: 'GET',
    },
    DOWNLOAD_FILE: {
      url: HRM_SYSTEM + '/register-in-out/export-excel',
      method: 'GET',
    },
    GET_ALL_CAMERA: {
      url: HRM_SYSTEM + '/cameras',
      method: 'GET',
    },
  },
  dashboard: {
    GET_REPORT_EXIT_EARLY: {
      url: HRM_SYSTEM + '/dashboards/stat-exit-early',
      method: 'GET',
    },
    GET_TOP_EXIT_EARLY: {
      url: HRM_SYSTEM + '/dashboards/top-exit-early',
      method: 'GET',
    },
    GET_REPORT_LATED: {
      url: HRM_SYSTEM + '/dashboards/stat-lated',
      method: 'GET',
    },
    GET_TOP_LATED: {
      url: HRM_SYSTEM + '/dashboards/top-lated',
      method: 'GET',
    },
    GET_REPORT_DAY_OFF: {
      url: HRM_SYSTEM + '/dashboards/stat-day-off',
      method: 'GET',
    },
  },
  BUILDING: {
    FLOOR_BY_BUILDING_ID: {
      url: HRM_SYSTEM + '/building/by-building-id',
      method: 'GET',
    },
    FLOOR_BY_UNIT_ID: {
      url: HRM_SYSTEM + '/building/by-unit-id',
      method: 'GET',
    },
    GET_NAME_BUILDING_ID: {
      url: HRM_SYSTEM + '/building',
      method: 'GET',
    },
  },
  LANGUAGE: {
    GET_LANG: {
      url: HRM_SYSTEM + '/lang-pack/',
      method: 'GET',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/lang-pack/update',
      method: 'POST',
    },
    RESET: {
      url: HRM_SYSTEM + '/lang-pack/reset',
      method: 'POST',
    },
  },
};
