<template>
  <div>
    <div class="text-sm text-hrm-gray500 truncate">
      {{ label }}
    </div>
    <a-popover placement="bottom" v-if="value.length > 40">
      <template #content>
        <div class="p-2">{{ value }}</div>
      </template>
      <div
        class="text-sm pt-[15px] pb-[5px] border-b border-b-hrm-gray200 truncate cursor-pointer min-h-[41px]"
      >
        {{ value }}
      </div>
    </a-popover>
    <div
      v-else
      class="text-sm pt-[15px] pb-[5px] border-b border-b-hrm-gray200 min-h-[41px] value-block truncate"
    >
      {{ value }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'InputField',
  props: {
    label: VueTypes.string,
    value: VueTypes.string,
  },
});
</script>

<style></style>
