<template>
  <a-row>
    <a-col :span="24">
      <div class="flex items-center justify-end">
        <cds-button
          v-if="permissions.hasReset"
          class="ml-10 mr-10 sDash_form-action__btn"
          html-type="button"
          type="primary"
          size="large"
          :color="'#4C9008'"
          @click="emitEvent('onClear')"
        >
          <img
            src="@/static/img/icon/ResetIcon.svg"
            alt="ResetIcon"
            class="w-[15px] h-[15px] mr-[8px]"
          />
          {{ t('common.refresh') }}
        </cds-button>
        <cds-button
          v-if="permissions.hasSearch"
          class="ml-10 mr-10 sDash_form-action__btn"
          type="primary"
          size="large"
          @click="emitEvent('onSearch')"
        >
          {{ t('common.search') }}
        </cds-button>
        <cds-button
          v-if="permissions.hasDownTemplate"
          class="ml-10 mr-10 sDash_form-action__btn"
          type="link"
          size="large"
          @click="emitEvent('onDownTemplate')"
        >
          <img
            src="@/static/img/icon/down-template-icon.svg"
            alt="UploadIcon"
            class="w-[15px] h-[15px] mr-[8px]"
          />
          {{ t('common.download_template') }}
        </cds-button>
        <div v-if="permissions.hasUpload" class="ml-10 mr-10">
          <label htmlFor="upload-profile">
            <div
              class="h-[38px] px-[16px] bg-hrm-main2 rounded flex text-hrm-white gap-[8px] items-center justify-center uppercase text-sm font-medium cursor-pointer"
            >
              <img
                src="@/static/img/icon/UploadIcon.svg"
                alt="UploadIcon"
                class="w-[15px] h-[15px]"
              />
              {{ t('common.upload') }}
            </div>
          </label>

          <input
            type="file"
            @change="handleChange"
            id="upload-profile"
            class="invisible absolute top-0 left-0 upload-button"
            accept=".zip"
          />
        </div>
        <cds-button
          v-if="permissions.hasCreate"
          class="ml-10 mr-10 sDash_form-action__btn"
          type="primary"
          size="large"
          @click="emitEvent('onCreate')"
        >
          <img
            src="@/static/img/icon/PlusIcon.svg"
            alt="PlusIcon"
            class="w-[15px] h-[15px] mr-[8px]"
          />
          {{ t('common.add') }}
        </cds-button>
        <cds-button
          v-if="permissions.hasCreateMenu"
          class="mr-10 sDash_form-action__btn"
          type="primary"
          size="large"
          @click="emitEvent('onCreateMenu')"
        >
          {{ t('common.add-menu') }}
        </cds-button>
        <cds-button
          v-if="permissions.hasCreateButton"
          class="mr-10 sDash_form-action__btn"
          type="primary"
          size="large"
          @click="emitEvent('onCreateButton')"
        >
          {{ t('common.add-button') }}
        </cds-button>
        <cds-button
          v-if="permissions.hasExportExcel"
          class="mr-10 sDash_form-action__btn"
          type="primary"
          size="large"
          @click="emitEvent('onExportExcel')"
        >
          {{ t('common.export') }}
        </cds-button>
        <cds-button
          v-if="permissions.hasApproval"
          class="mr-10 sDash_form-action__btn"
          type="primary"
          size="large"
          @click="emitEvent('onApproval')"
        >
          {{ t('common.approval') }}
        </cds-button>
        <cds-button
          v-if="permissions.hasCancelApproval"
          class="mr-10 sDash_form-action__btn"
          type="danger"
          size="large"
          @click="emitEvent('onCancelApproval')"
        >
          {{ t('common.cancel-approval') }}
        </cds-button>
        <cds-button
          v-if="permissions.hasDelete"
          class="ml-10 mr-10 sDash_form-action__btn"
          type="danger"
          size="large"
          @click="emitEvent('onDelete')"
        >
          <img
            src="@/static/img/icon/DeleteIcon.svg"
            alt="ResetIcon"
            class="w-[15px] h-[15px] mr-[8px]"
          />
          {{ t('common.delete') }}
        </cds-button>
        <cds-button
          v-if="permissions.hasUnPublic"
          class="ml-10 mr-10 sDash_form-action__btn"
          type="primary"
          outlined
          size="large"
          @click="emitEvent('onUnPublic')"
        >
          {{ t('common.remove-post') }}
        </cds-button>
        <cds-button
          v-if="permissions.hasDownload"
          class="ml-10 mr-10 sDash_form-action__btn"
          type="primary"
          size="large"
          @click="emitEvent('onDownload')"
        >
          <img
            src="@/static/img/icon/icon-download.svg"
            alt="ResetIcon"
            class="w-[20px] h-[20px] mr-[8px]"
          />
          {{ t('common.download') }}
        </cds-button>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import VueTypes from 'vue-types';
import { computed, defineComponent, watch, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SearchFormButton',
  props: {
    buttons: VueTypes.array.def([]),
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const emitEvent = (event) => {
      emit(event);
    };
    const { state } = useStore();
    const perms = computed(() => state.auth.userInfo.permissions);
    const hasButton = (button) =>
      (props.buttons || []).some(
        (e) =>
          e.type === button &&
          (!e.permission || (perms.value || []).includes(e.permission))
      );
    const permissions = ref({
      hasReset: false,
      hasSearch: false,
      hasCreate: false,
      hasCreateMenu: false,
      hasCreateButton: false,
      hasDelete: false,
      hasApproval: false,
      hasCancelApproval: false,
      hasExportExcel: false,
      hasUnPublic: false,
      hasUpload: false,
      hasDownload: false,
    });
    const fetchPerm = () => {
      permissions.value = {
        hasReset: hasButton('RESET'),
        hasSearch: hasButton('SEARCH'),
        hasCreate: hasButton('CREATE'),
        hasCreateMenu: hasButton('CREATE_MENU'),
        hasCreateButton: hasButton('CREATE_BUTTON'),
        hasDelete: hasButton('DELETE'),
        hasApproval: hasButton('APPROVAL'),
        hasCancelApproval: hasButton('CANCEL_APPROVAL'),
        hasExportExcel: hasButton('EXPORT_EXCEL'),
        hasUnPublic: hasButton('UN_PUBLISH'),
        hasUpload: hasButton('UPLOAD'),
        hasDownload: hasButton('DOWNLOAD'),
        hasDownTemplate: hasButton('DOWNLOAD-TEMPLATE'),
      };
    };
    watch(() => perms.value, fetchPerm);
    onMounted(fetchPerm);
    const handleChange = async (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      emit('onUpload', file);
      document.getElementsByClassName(`upload-button`)[0].value = null;
    };
    return {
      emitEvent,
      permissions,
      t,
      handleChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.sDash_form-action {
  &__btn {
    @apply uppercase;
  }
}
</style>
