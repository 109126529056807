import {i18n} from "@/main";
import { graphic } from 'echarts';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setChartPieIn(state) {
    const {t} = i18n.global
    state.chartPieIn = [
      {value: state.dataStaff.totalIn, name: t('access_control.employee')},
      {value: state.dataCustomer.totalIn, name: t('access_control.client')},
      {value: state.dataStranger.strangerIn, name: t('access_control.stranger')},
    ]
  },
  setChartPieOut(state) {
    const {t} = i18n.global
    state.chartPieOut = [
      {value: state.dataStaff.totalOut, name: t('access_control.employee')},
      {value: state.dataCustomer.totalOut, name: t('access_control.client')},
      {value: state.dataStranger.strangerOut, name: t('access_control.stranger')},
    ]
  },
  setChartLineIn(state, statisticIn) {
    state.chartLineIn.data = statisticIn.map(e => e.key)
    state.chartLineIn.series = [
      {
        data: statisticIn.map(e => e.staff),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#62b2fd',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(54, 132, 214, 0.2)'
            },
            {
              offset: 1,
              color: 'rgba(0, 35, 64, 0.2)'
            }])
        },
      },
      {
        data: statisticIn.map(e => e.customer),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#f99bab',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(200, 2, 35, 0.2)'
            },
            {
              offset: 1,
              color: 'rgba(64, 7, 18, 0.2)'
            }])
        },
      },
      {
        data: statisticIn.map(e => e.stranger),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#9bdfc4',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(23, 228, 122, 0.06)'
            },
            {
              offset: 1,
              color: 'rgba(0, 64, 34, 0.2)'
            }])
        },
      },
    ]
  },
  setChartLineOut(state, statisticOut) {
    state.chartLineOut.data = statisticOut.map(e => e.key)
    state.chartLineOut.series = [
      {
        data: statisticOut.map(e => e.staff),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#62b2fd',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(54, 132, 214, 0.2)'
            },
            {
              offset: 1,
              color: 'rgba(0, 35, 64, 0.2)'
            }])
        },
      },
      {
        data: statisticOut.map(e => e.customer),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#f99bab',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(200, 2, 35, 0.2)'
            },
            {
              offset: 1,
              color: 'rgba(64, 7, 18, 0.2)'
            }])
        },
      },
      {
        data: statisticOut.map(e => e.stranger),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#9bdfc4',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(23, 228, 122, 0.06)'
            },
            {
              offset: 1,
              color: 'rgba(0, 64, 34, 0.2)'
            }])
        },
      },
      
    ]
  },
};
