<template>
  <div class="flex items-center justify-center">
    <a-tooltip :title="t('common.view')" v-if="hasView">
      <a-button
        type="ghost"
        shape="circle"
        @click="emitEvent('onView')"
        class="text-white mr-5 py-0 hover:scale-125 border-none"
      >
        <template #icon>
          <InlineSvg
            src="/assets/view.svg"
            alt="edit"
            width="32"
            height="32"
            class="fill-current text-hrm-main2 hover:!text-[#E19A26]"
          />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip :title="t('common.edit')" v-if="hasEdit">
      <a-button
        type="ghost"
        style="color: #ff4d4f"
        shape="circle"
        :disabled="disabledEdit"
        class="text-white mr-5 py-0 hover:scale-125 border-none"
        @click="emitEvent('onEdit')"
      >
        <template #icon>
          <InlineSvg
            src="/assets/edit.svg"
            alt="edit"
            width="32"
            height="32"
            class="fill-current text-hrm-main3 hover:!text-[#3A7300]"
          />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip
      :title="t('common.cancel-1')"
      v-if="hasCancelApproval && isShowApproval"
    >
      <a-button
        type="ghost"
        class="mr-5"
        style="color: #ff4d4f"
        shape="circle"
        @click="emitEvent('onCancelApproval')"
      >
        <template #icon>
          <cds-feather-icons type="x" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip :title="t('common.show')" v-if="hasShow && !isShow">
      <a-button
        type="ghost"
        class="mr-5"
        style="color: #20c997"
        shape="circle"
        @click="emitEvent('onShow')"
      >
        <template #icon>
          <cds-feather-icons type="eye" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip :title="t('common.hidden')" v-if="hasHidden && isShow">
      <a-button
        type="ghost"
        class="mr-5"
        style="color: #ff4d4f"
        shape="circle"
        @click="emitEvent('onHidden')"
      >
        <template #icon>
          <cds-feather-icons type="eye-off" />
        </template>
      </a-button>
    </a-tooltip>

    <a-tooltip :title="t('common.approval')" v-if="hasApproval">
      <a-button
        type="primary"
        style="color: #fff"
        shape="circle"
        :disabled="disabledApproval"
        class="text-white bg-[#20c997] mr-5 hover:bg-[#20c997] focus:bg-[#20c997]"
        @click="emitEvent('onApproval')"
      >
        <template #icon>
          <cds-feather-icons type="check" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip :title="t('common.re-edit')" v-if="hasReEdit">
      <a-button
        type="primary"
        style="color: #ff4d4f"
        shape="circle"
        :disabled="disabledReEdit"
        class="text-white bg-[#ff4d4f] mr-5 hover:bg-[#ff4d4f] focus:bg-[#ff4d4f]"
        @click="emitEvent('onReEdit')"
      >
        <template #icon>
          <cds-feather-icons type="x" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip :title="t('common.publish')" v-if="hasPublish">
      <a-button
        type="primary"
        style="color: #fff"
        shape="circle"
        :disabled="disabledPublish"
        class="text-white bg-[#20c997] mr-5 hover:bg-[#20c997] focus:bg-[#20c997]"
        @click="emitEvent('onPublish')"
      >
        <template #icon>
          <cds-feather-icons type="check" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip :title="t('common.review')" v-if="hasRePublish">
      <a-button
        type="primary"
        style="color: #fff"
        shape="circle"
        :disabled="disabledRePublish"
        class="text-white bg-[#ff4d4f] mr-5 hover:bg-[#ff4d4f] focus:bg-[#ff4d4f]"
        @click="emitEvent('onReApproval')"
      >
        <template #icon>
          <cds-feather-icons type="x" />
        </template>
      </a-button>
    </a-tooltip>

    <a-tooltip :title="t('common.delete')" v-if="hasDelete">
      <a-button
        type="ghost"
        style="color: #ff4d4f"
        shape="circle"
        class="py-0 border-none hover:scale-125 mr-5"
        @click="emitEvent('onDelete')"
      >
        <template #icon>
          <InlineSvg
            src="/assets/delete.svg"
            alt="delete"
            width="32"
            height="32"
            class="fill-current text-hrm-coBan hover:!text-[#00839F]"
          />
        </template>
      </a-button>
    </a-tooltip>

    <a-tooltip :title="t('common.lock')" v-if="hasBlock">
      <a-button
        type="ghost"
        style="color: #ff4d4f"
        shape="circle"
        class="py-0 border-none hover:scale-125"
        @click="emitEvent('onBlock')"
      >
        <template #icon>
          <InlineSvg
            src="/assets/khoa.svg"
            alt="block"
            width="32"
            height="32"
            class="fill-current text-hrm-lam hover:!text-[#2045A4]"
          />
        </template>
      </a-button>
    </a-tooltip>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import InlineSvg from 'vue-inline-svg';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ActionButton',
  components: { InlineSvg },
  props: {
    // {type, permission}
    buttons: VueTypes.array.def([]),
    disabledEdit: VueTypes.bool.def(false),
    disabledApproval: VueTypes.bool.def(false),
    disabledReEdit: VueTypes.bool.def(false),
    disabledPublish: VueTypes.bool.def(false),
    disabledRePublish: VueTypes.bool.def(false),
    isShowApproval: VueTypes.bool.def(false),
    isShow: VueTypes.bool.def(false),
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const emitEvent = (event) => {
      emit(event);
    };
    const { state } = useStore();
    const permissions = computed(() => state.auth.userInfo.permissions || []);
    const hasButton = (button) =>
      props.buttons.some(
        (e) =>
          e.type === button &&
          (!e.permission || permissions.value.includes(e.permission))
      );
    return {
      emitEvent,
      hasEdit: hasButton('EDIT'),
      hasView: hasButton('VIEW'),
      hasDelete: hasButton('DELETE'),
      hasReEdit: hasButton('RE-EDIT'),
      hasApproval: hasButton('APPROVAL'),
      hasRePublish: hasButton('RE_PUBLISH'),
      hasPublish: hasButton('PUBLISH'),
      hasCancelApproval: hasButton('CANCEL_APPROVAL'),
      hasShow: hasButton('SHOW'),
      hasHidden: hasButton('HIDDEN'),
      hasBlock: hasButton('BLOCK'),
      t,
    };
  },
});
</script>

<style scoped></style>
