<template>
  <p v-if="readOnly">{{ getText() }}</p>
  <a-tree-select
    v-else
    v-model:value="valueSelect"
    show-search
    style="width: 100%"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    :placeholder="placeholder"
    allow-clear
    :tree-data="treeData"
    :fieldNames="fieldNames"
    size="small"
    :filterTreeNode="filterTreeNode"
    :showSearch="true"
    @change="change"
  >
  </a-tree-select>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import VueTypes from 'vue-types';
import _ from 'lodash';

export default defineComponent({
  name: 'TreeSelect',
  props: {
    treeData: VueTypes.array.def([]),
    value: VueTypes.oneOfType([
      VueTypes.array,
      VueTypes.string,
      VueTypes.number,
    ]).def(),
    checkStrictly: VueTypes.bool.def(true),
    fieldNames: VueTypes.object,
    placeholder: VueTypes.string.def(''),
    readOnly: VueTypes.bool.def(false),
  },
  setup(props, { emit }) {
    const valueSelect = ref(props.value);
    const treeData = ref(props.treeData);
    const fieldNames = ref(props.fieldNames);
    watch(
      () => props.value,
      (val) => (valueSelect.value = val)
    );
    const filterTreeNode = (inputValue, treeNode) => {
      return (
        treeNode.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      );
    };
    const change = (val) => emit('update:value', val);
    const getText = computed(() => () => {
      const flattenData = treeData.value.reduce((newArr, arr) => {
        const { children, ...rest } = arr;
        newArr.push(rest);
        return newArr.concat(children);
      }, []);
      const parent = flattenData.find(
        (e) =>
          e &&
          !_.isNil(valueSelect.value) &&
          e[fieldNames.value.value] == valueSelect.value
      );
      console.log(valueSelect.value, parent);
      if (parent) return parent[fieldNames.value.label];
    });
    return {
      valueSelect,
      filterTreeNode,
      change,
      getText,
    };
  },
});
</script>

<style scoped></style>
