import mutations from '../articles/mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { FORM_MODE } from '@/util/common-constant';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import { trimValueObj } from '@/util/common-utils';
import { showConfirm } from '@/util/confirm';
import dayjs from 'dayjs';

const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  articles: {},
  api: ConstantAPI.TIME,
  visible: false,
  selectedItem: null,
  formMode: FORM_MODE.VIEW,
  formState: {
    active: true,
    breakTime: 0,
    finishBreakTime: null,
    finishTime: null,
    id: 0,
    startBreakTime: null,
    startTime: null,
    workShiftName: '',
  },
});

const actions = {
  preCreate({ state }) {
    state.formMode = FORM_MODE.CREATE;
    state.formState = {
      active: true,
      breakTime: 0,
      finishBreakTime: null,
      finishTime: null,
      id: 0,
      startBreakTime: null,
      startTime: null,
      workShiftName: '',
    };
  },
  resetForm({ state }) {
    state.formState = {
      active: true,
      breakTime: 0,
      finishBreakTime: null,
      finishTime: null,
      id: 0,
      startBreakTime: null,
      startTime: null,
      workShiftName: '',
    };
  },
  changeAction({ state }, formMode) {
    state.formMode = formMode;
  },
  async getDetail({ state }, id) {
    try {
      const response = await DataService.callApi(state.api.GET_DETAIL, null, {
        workShiftId: id,
      });
      let success;
      checkResponse(
        response,
        () => {
          success = true;
          state.formState = response.data;
          state.formState.finishTime = state.formState.finishTime
            ? dayjs(state.formState.finishTime, 'YYYY-MM-DD HH:mm:ss')
            : null;
          state.formState.startTime = state.formState.startTime
            ? dayjs(state.formState.startTime, 'YYYY-MM-DD HH:mm:ss')
            : null;
          state.formState.startBreakTime = state.formState.startBreakTime
            ? dayjs(state.formState.startBreakTime, 'YYYY-MM-DD HH:mm:ss')
            : null;
          state.formState.finishBreakTime = state.formState.finishBreakTime
            ? dayjs(state.formState.finishBreakTime, 'YYYY-MM-DD HH:mm:ss')
            : null;
        },
        () => {
          success = false;
        }
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async create({ state }, data) {
    const { t } = i18n.global;
    try {
      trimValueObj(data);
      const response = await DataService.callApi(state.api.CREATE, data);
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('time.create-time-success'),
          duration: 4,
        });
        state.formMode = FORM_MODE.VIEW;
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, params) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('time.confirm-delete-time'))) {
        const response = await DataService.callApi(state.api.DELETE, {
          workShiftId: params.workShiftId,
        });
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('time.delete-success'),
            duration: 4,
          });
          state.formMode = FORM_MODE.VIEW;
        });
        return success;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async update({ state }, data) {
    const { t } = i18n.global;
    try {
      trimValueObj(data);
      const response = await DataService.callApi(state.api.UPDATE, data);
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('time.update-success'),
          duration: 4,
        });
        state.formMode = FORM_MODE.VIEW;
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
