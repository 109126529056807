import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.user = {
      avatar: '',
      username: '',
      password: '',
      userRoles: [],
      profileId: null,
      unitId: null,
      status: 1,
      isBuilding: true,
      isUnit: false,
      position: 1,
    };
  },
  setFormValue(state, { user, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.user = {
      ...user,
    };
  },
};
