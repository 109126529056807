import { FORM_MODE } from '@/util/common-constant';
import {STATUS_ACTION} from "@/config/Constant";

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.role = {
      name: '',
      remark: '',
      status: STATUS_ACTION.ACTIVE,
      roleMenus: [],
    };
  },
  setFormValue(state, { role, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.role = {
      ...role,
    };
  },
  getAllSuccess(state, data) {
    state.list = data;
  },
};
