import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {getField, updateField} from 'vuex-map-fields';
import {DataService} from "@/dataService/dataService";
import {checkResponse} from "@/util/common-utils";
import {notification} from "ant-design-vue";
import {i18n} from "@/main";

const state = () => ({
  list: [],
  loading: false,
  api: ConstantAPI.ACCESS_CONTROL,
  dataStranger: null,
  dataStaff: null,
  dataCustomer: null,
  chartPieIn: null,
  chartPieOut: null,
  chartLineIn: {
    data: null,
    series: []
  },
  chartLineOut: {
    data: null,
    series: []
  },
  listCamera: [],
  listStranger: []
});

const actions = {
  async getData({ commit, state }, params) {
    commit('setLoading', true)
    const response = await DataService.callApi(state.api.DASHBOARD, null, params);
    checkResponse(response, () => {
      state.dataStranger = response.data.stranger
      state.dataStaff = response.data.staff
      state.dataCustomer = response.data.customer
      commit('setChartPieIn')
      commit('setChartPieOut')
      commit('setChartLineIn', response.data.statisticIn)
      commit('setChartLineOut', response.data.statisticOut)
    });
    commit('setLoading', false)
  },
  async download({ commit, state }, param) {
    const {t} = i18n.global
    try {
      commit('setLoading', true);
      notification.info({
        message: t('common.notification'),
        description: t('common.exporting'),
        duration: 4,
      });
      const response = await DataService.getConfig(
          state.api.DOWNLOAD_FILE.url,
          param,
          null,
          'blob'
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'thong_ke_khach_hang.xlsx');
      document.body.appendChild(link);
      link.click();
      notification.success({
        message: t('common.notification'),
        description: t('common.export-success'),
        duration: 4,
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async getAllCamera({ state }, params) {
    const response = await DataService.callApi(state.api.GET_ALL_CAMERA, null, params);
    checkResponse(response, () => {
      state.listCamera = response.data || []
    });
  },
  async getStatisticStranger({state, commit}, params) {
    try {
      commit('setLoading', true)
      const response = await DataService.callApi(state.api.DASHBOARD_STRANGER, null, params);
      checkResponse(response, () => {
        state.listStranger = response.data;
      });
      commit('setLoading', false)
    } catch(e) {
      commit('setLoading', false)
      console.log(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
