import store from '@/vuex/store';

const guard = async (from) => {
  if (store.getters.allMenu.includes(from.path)) {
    return true;
  }
  return { path: '404' };
};

const adminRoutes = [
  {
    path: '',
    redirect: () => {
      return '/home';
    },
  },
  {
    path: 'home',
    name: 'dashboard',
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/access-control/AccessControl.vue'
      ),
  },
  {
    path: 'settings',
    name: 'settings',
    component: () =>
      import(
        /* webpackChunkName: "Settings" */ '@/view/pages/settings/Settings.vue'
      ),
    children: [
      {
        path: 'profile-settings',
        name: 'profile-settings',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
          ),
      },
      {
        path: 'profile-settings/profile',
        name: 'set-profile',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
          ),
      },
      {
        path: 'profile-settings/account',
        name: 'set-account',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Account.vue'
          ),
      },
      {
        path: 'profile-settings/password',
        name: 'set-password',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Password.vue'
          ),
      },
    ],
  },
  {
    path: 'system',
    name: 'system',
    children: [
      {
        path: 'menu',
        name: 'menu-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/menu/Menu.vue'
          ),
      },
      {
        path: 'role',
        name: 'role-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/role/Role.vue'
          ),
      },
      {
        path: 'user',
        name: 'user-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/user/User.vue'
          ),
      },
      {
        path: 'language',
        name: 'language',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/language/Language.vue'
          ),
      },
    ],
  },
  {
    path: 'profile-management',
    name: 'profile-management',
    children: [
      {
        path: '',
        name: 'list-employee',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/profile-management/list-employee.vue'
          ),
      },
      {
        path: 'create',
        name: 'profile-create',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/form.vue'
          ),
      },
      {
        path: 'edit',
        name: 'profile-edit',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/form.vue'
          ),
      },
      {
        path: 'view',
        name: 'profile-detail',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/detail.vue'
          ),
      },
      {
        path: 'customers',
        name: 'list-customer',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/profile-management/list.vue'
          ),
      },
      {
        path: 'customers/view',
        name: 'customer-profile-detail',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/detail.vue'
          ),
      },
      {
        path: 'customers/create',
        name: 'customer-profile-create',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/form.vue'
          ),
      },
      {
        path: 'customers/edit',
        name: 'customer-profile-edit',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-management/form.vue'
          ),
      },
    ],
  },
  {
    path: 'unit-management',
    name: 'unit-management',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/unit-management/list.vue'
      ),
  },
  {
    path: 'attendance-management',
    name: 'attendance-management',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/attendance-management/list.vue'
      ),
  },
  {
    path: 'time-management',
    name: 'time-management',
    beforeEnter: [guard],
    children: [
      {
        path: '',
        name: 'list-time',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/time-management/list.vue'
          ),
      },
      {
        path: 'edit',
        name: 'time-edit',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/time-management/edit.vue'
          ),
      },
      {
        path: 'create',
        name: 'time-create',
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/time-management/create.vue'
          ),
      },
    ],
  },

  {
    path: 'event-management',
    name: 'event-management',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/event-management/list.vue'
      ),
  },
  {
    path: 'access-control',
    name: 'access-control',
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/access-control/AccessControl.vue'
      ),
  },
  {
    path: 'attendance-report',
    name: 'attendance-report',
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/attendance-report/AttendanceReport.vue'
      ),
  },
  {
    path: 'register-in-out',
    name: 'register-in-out',
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/register-in-out/RegisterInOut.vue'
      ),
  },
  {
    path: 'report',
    name: 'report',
    children: [
      {
        path: 'timekeeping',
        name: 'timekeeping',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager"  */ '@/view/manager/timekeeping/list.vue'
          ),
      },
    ],
  },
  {
    path: 'lane-control',
    name: 'lane-control',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/lane-control/list.vue'
      ),
  },
  {
    path: 'intrusion-event',
    name: 'intrusion-event',
    beforeEnter: [guard],
    component: () =>
      import(
        /* webpackChunkName: "Manager"  */ '@/view/manager/intrusion-event/list.vue'
      ),
  },
];

export default adminRoutes;
