import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { FORM_MODE, USER_POSITION } from '@/util/common-constant';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { showConfirm } from '@/util/confirm';
import {i18n} from '@/main';

const state = () => ({
  list: [],
  loading: false,
  user: {},
  api: ConstantAPI.user,
  visible: false,
  listDepartmentById: [],
  profileName: '',
});

const actions = {
  async getAll({ commit, state }, userInfo) {
    try {
      const response = await DataService.callApi(
        state.api.SEARCH,
        null,
        userInfo
      );
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async preCreate({ commit }) {
    commit('preCreate');
  },
  async preUpdate({ commit }, row) {
    const response = await DataService.callApi(
      ConstantAPI.user_role.GET_BY_USER,
      null,
      { userId: row.id }
    );
    commit('setFormValue', {
      user: {
        ...row,
        userRoles: (response.data || []).map((e) => e.roleId),
        isBuilding: row.position === USER_POSITION.ADMIN_TOA_NHA,
        isUnit: row.position === USER_POSITION.ADMIN_DON_VI,
      },
      formMode: FORM_MODE.UPDATE,
    });
  },
  async preView({ commit }, row) {
    const response = await DataService.callApi(
      ConstantAPI.user_role.GET_BY_USER,
      null,
      { userId: row.id }
    );
    commit('setFormValue', {
      user: { ...row, userRoles: (response.data || []).map((e) => e.roleId) },
      formMode: FORM_MODE.VIEW,
    });
  },
  async create({ commit, state }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_save'))) {
        commit('setLoading', true);
        const data = {
          ...payload,
          userRoles: payload.userRoles.map((e) => ({ roleId: e })),
        };
        const response = await DataService.callApi(
          state.api.CREATE,
          data,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('user.create_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async update({ commit, state }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_save'))) {
        commit('setLoading', true);
        const data = {
          ...payload,
          userRoles: (payload.userRoles || []).map((e) => ({ roleId: e })),
          userId: state.user.userId,
        };
        const response = await DataService.callApi(
          state.api.UPDATE,
          data,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('user.update_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async delete({ state }, rows) {
    const { t } = i18n.global;
    try {
      if (!rows.length) {
        notification.error({
          message: t('common.notification'),
          description: t('common.no_record_selected'),
          duration: 4,
        });
        return false;
      }
      if (await showConfirm(t('common.confirm_delete'))) {
        const response = await DataService.delete(
          state.api.DELETE.url + '/' + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('user.delete_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async setVisible({ state }, visible) {
    state.visible = visible;
  },
  async getDetailProfile({ state }, id) {
    const response = await DataService.callApi({
      url: ConstantAPI.profileManagement.GET_BY_IDS.url + id,
      method: ConstantAPI.profileManagement.GET_BY_IDS.method,
    });
    checkResponse(response, (res) => {
      state.profileName = res.fullname;
    }, () => {
      state.profileName = null;
    });
  },
  async resetPassword({ state }, id) {
    const { t } = i18n.global;
    const response = await DataService.callApi({
      url: state.api.RESET_PASSWORD.url + id,
      method: state.api.RESET_PASSWORD.method,
    });
    checkResponse(response, () => {
      notification.success({
        message: t('common.notification'),
        description: t('user.reset_password_success'),
        duration: 4,
      });
    }, () => {
      state.profileName = null;
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
