import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { i18n } from '@/main';
import { PROFILE_TYPE } from '@/util/common-constant';

const state = () => ({
  loading: false,
  listUnit: [],
  listDepartmentById: [],
  listRole: [],
  listBuilding: [],
  listProfile: [],
});

const actions = {
  async getAllListUnit({ state }) {
    try {
      const response = await DataService.callApi(ConstantAPI.LIST_UNIT.GET_ALL);
      checkResponse(
        response,
        (res) => {
          state.listUnit = res.content;
        },
        () => (state.listUnit = [])
      );
    } catch (err) {
      console.log(err);
    }
  },
  async getListDepartmentByUnitId({ state }, unitId) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.LIST_DEPARTMENT.GET_ALL,
        null,
        { unitId }
      );
      checkResponse(
        response,
        (res) => {
          state.listDepartmentById = res.content ? res.content : [];
        },
        () => (state.listDepartmentById = [])
      );
    } catch (err) {
      console.log(err);
    }
  },
  async getListRoleByUserId({ state }) {
    try {
      const response = await DataService.callApi(ConstantAPI.role.GET_ALL);
      checkResponse(
        response,
        (res) => {
          state.listRole = res ? res : [];
        },
        () => (state.listRole = [])
      );
    } catch (err) {
      console.log(err);
    }
  },
  async getAllListBuilding({ state }, isHaveAll = true) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
        ConstantAPI.LIST_BUILDING.GET_ALL
      );
      checkResponse(
        response,
        (res) => {
          state.listBuilding = res
            ? isHaveAll
              ? [{ id: '0', name: t('common.all') }, ...res]
              : res
            : [];
        },
        () => (state.listBuilding = [])
      );
    } catch (err) {
      console.log(err);
    }
  },
  async getListProfile({ state }, { unitId, departmentId }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.profileManagement.GET_LIST_NAME,
        null,
        { profileType: PROFILE_TYPE.EMPLOYEE, unitId, departmentId }
      );
      checkResponse(
        response,
        (res) => {
          state.listProfile = res ? res : [];
        },
        () => (state.listProfile = [])
      );
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
