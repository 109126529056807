<template>
  <div
    class="table-custom relative"
    :class="pagination ? 'h-[calc(100%-60px)]' : 'h-full'"
  >
    <a-table
      ref="table"
      class="scroll-table-to-top h-full"
      :columns="tableColumns"
      :data-source="data"
      :clientPagination="clientPagination"
      :pagination="clientPagination"
      :tableLayout="tableLayout"
      :showSorterTooltip="showSorterTooltip"
      :loading="loading"
      :rowSelection="rowSelection"
      @resizeColumn="handleResizeColumn"
      :custom-row="customRow"
      :rowKey="keyField"
      :scroll="{ x: 1500, y: scrollHeight, scrollToFirstRowOnChange: true }"
      :bordered="bordered"
    >
      <template v-if="title" #title>{{ title }}</template>
      <template #headerCell="{ column }">
        <slot name="custom-header" :column="column">
          <div
            class="uppercase font-bold"
            :class="column.align === 'center' ? 'text-center' : 'text-left'"
          >
            {{ column.title }}
          </div>
        </slot>
      </template>
      <template #bodyCell="{ column, record }">
        <slot name="custom-body" :column="column" :record="record"></slot>
      </template>
      <slot name="custom-column"></slot>
    </a-table>
  </div>
  <div
    v-if="pagination && total"
    class="absolute ant-pagination-table bg-hrm-white bottom-[15px] left-0 w-full flex justify-center items-center"
  >
    <span
      class="absolute bottom-[4px] text-ems-gray500 text-sm right-[110px]"
      >{{ $t('common.size-changer-text') }}</span
    >
    <a-pagination
      :show-size-changer="showSizeChanger"
      v-model:current="page"
      v-model:page-size="size"
      :total="total"
      :page-size-options="pageSizeOptions"
      @change="onChangePage"
      @show-size-change="sizeChange"
      :show-total="
        showTotal ? (total) => `${$t('common.total')}: ${total}` : ''
      "
      class="flex justify-center items-center w-full px-[30px] bg-hrm-white"
    />
  </div>
</template>

<script src="./table-controller.js"></script>
<style lang="scss">
.ant-pagination-table {
  .ant-pagination-options {
    flex: 1;
    .ant-pagination-options-size-changer {
      @apply absolute bottom-0 right-[30px];
    }
  }
  .ant-pagination-total-text {
    flex: 1;
  }
}
</style>
