import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import { trimValueObj } from '@/util/common-utils';
import { showConfirm } from '@/util/confirm';

const state = () => ({
  list: [],
  loading: false,
  api: ConstantAPI.ATTENDANCE,
  visible: false,
  listAggregate: [],
  totalAggregate: 0,
});

const actions = {
  async changeStatus({ state }, ids) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('attendance.confirm-change-status'))) {
        const response = await DataService.callApi(state.api.CHANGE_STATUS, {
          recordIds: ids,
        });
        let success;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('attendance.change-status-success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async export({ state }, params) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('attendance.confirm-export'))) {
        trimValueObj(params);
        notification.info({
          message: t('common.notification'),
          description: t('common.exporting'),
          duration: 4,
        });
        const response = await DataService.getConfig(
          state.api.EXPORT.url,
          params,
          null,
          'blob'
        );
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `attendance-${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        notification.success({
          message: t('common.notification'),
          description: t('common.export-success'),
          duration: 4,
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  async getAggregate({ state }, params) {
    try {
      const response = await DataService.callApi(state.api.AGGREGATE, null, {
        ...params,
        page: params.page - 1,
      });
      let success;
      checkResponse(response, () => {
        success = true;
        state.listAggregate = response.data.content
          ? response.data.content
          : [];
        state.totalAggregate = response.data.totalElements;
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async exportDetail({ state }, params) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('attendance.confirm-export'))) {
        trimValueObj(params);
        notification.info({
          message: t('common.notification'),
          description: t('common.exporting'),
          duration: 4,
        });
        const response = await DataService.getConfig(
          state.api.EXPORT_DETAIL.url,
          params,
          null,
          'blob'
        );
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `attendance-detail-${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        notification.success({
          message: t('common.notification'),
          description: t('common.export-success'),
          duration: 4,
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  async exportGeneral({ state }, params) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('attendance.confirm-export'))) {
        trimValueObj(params);
        notification.info({
          message: t('common.notification'),
          description: t('common.exporting'),
          duration: 4,
        });
        const response = await DataService.getConfig(
          state.api.EXPORT_GENERAL.url,
          params,
          null,
          'blob'
        );
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `attendance-general-${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        notification.success({
          message: t('common.notification'),
          description: t('common.export-success'),
          duration: 4,
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
